import { toast, ToastContainer, ToastContainerProps } from "react-toastify";

const ToastProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const defaultToastOptions: ToastContainerProps = {
    position: "top-right",
    autoClose: 5 * 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    icon: ({ type }) => {
      switch (type) {
        case "success":
          return <img src="/img/apex-toast-success.webp" alt="happy toast" />;
        case "error":
          return <img src="/img/apex-toast-error.webp" alt="sad toast" />;
        case "warning":
          return (
            <img src="/img/apex-toast-verifying.webp" alt="verifying toast" />
          );
        default:
          return <img src="/img/apex-toast-error.webp" alt="sad toast" />;
      }
    },
    closeButton: () => (
      <img
        src="/img/apex-toast-close.webp"
        alt="close toast"
        style={{ width: "24px", height: "24px" }}
      />
    ),
  };

  return (
    <>
      {children}
      <ToastContainer {...defaultToastOptions} />
    </>
  );
};

export { ToastProvider };
